import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I'm Chris - I started this site as a technical journal dedicated to documenting my `}<span style={{
        "textTransform": "uppercase",
        "fontWeight": "bold",
        "fontSize": "1.25",
        "backgroundColor": "#f4900c",
        "padding": "5px",
        "fontStyle": "italic"
      }}>{`journey`}</span>{` through learning cloud technologies. The goal is to document key points and reminders as I progress through this expedition in hopes that if I write down what I am learning I will be more likely to remember it. The end goal here is to gain a holistic understanding of everything there is to know about cloud technologies and eventual obtain my AWS certification (SAA-C02) to become a `}<span style={{
        "textTransform": "uppercase",
        "fontWeight": "bold",
        "fontSize": "1.25",
        "backgroundColor": "#f4900c",
        "padding": "5px",
        "fontStyle": "italic"
      }}>{`certified solutions architect`}</span>{`. Hopefully this will be the first of many under the AWS certification umbrella.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      